"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mergeProperties = mergeProperties;
exports.copyObject = copyObject;
exports.defaultDescriptor = void 0;

function mergeProperties(dest, src) {
  Object.getOwnPropertyNames(src).forEach(function forEachOwnPropertyName(name) {
    if (Object.hasOwnProperty.call(dest, name)) {
      return;
    }

    var descriptor = Object.getOwnPropertyDescriptor(src, name);
    Object.defineProperty(dest, name, descriptor);
  });
  return dest;
}

var defaultDescriptor = {
  writable: true,
  enumerable: true,
  configurable: false
};
exports.defaultDescriptor = defaultDescriptor;

function copyObject(obj) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  function cloneObj() {
    var _options$skip = options.skip,
        skip = _options$skip === void 0 ? [] : _options$skip,
        _options$keep = options.keep,
        keep = _options$keep === void 0 ? [] : _options$keep;
    var clone = {};

    for (var key in obj) {
      if (keep.indexOf(key) === -1 && skip.indexOf(key) === -1 && obj.hasOwnProperty(key)) {
        clone[key] = copyObject(obj[key], options);
      }

      if (keep.indexOf(key) > -1) {
        clone[key] = obj[key];
      }
    }

    return clone;
  }

  function cloneArr() {
    return obj.map(function (item) {
      return copyObject(item, options);
    });
  }

  var transformedObject = obj && obj.toJSON ? obj.toJSON() : obj;
  var type = Object.prototype.toString.call(transformedObject).slice(8, -1).toLowerCase();

  if (type === "object") {
    return cloneObj();
  }

  if (type === "array") {
    return cloneArr();
  }

  return obj !== null && obj !== void 0 && obj.toJSON ? obj.toJSON() : obj;
}
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault")["default"];

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createDefaultStyleManager = createDefaultStyleManager;
exports["default"] = createStyleManager;

var _objectSpread2 = _interopRequireDefault(require("@babel/runtime/helpers/objectSpread2"));

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _utils = require("./utils");

function createDefaultStyleManager() {
  var p = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var wapp = p.wapp;
  var lastStyleElemId = null;

  function defaultInsertCss(css, moduleId) {
    var id = "s".concat(moduleId);
    var elem = document.getElementById(id);
    var create = false;

    if (!elem) {
      create = true;
      elem = document.createElement("style");
      elem.setAttribute("type", "text/css");
      elem.id = id;
    }

    var cssText = css;

    if ("textContent" in elem) {
      elem.textContent = cssText;
    } else {
      elem.styleSheet.cssText = cssText;
    }

    if (create) {
      if (lastStyleElemId && document.getElementById(lastStyleElemId)) {
        document.head.insertBefore(elem, document.getElementById(lastStyleElemId));
      } else {
        document.head.appendChild(elem);
      }

      lastStyleElemId = id;
    }

    return function remove() {
      var elem = document.getElementById(id);

      if (elem) {
        elem.parentNode.removeChild(elem);
      }
    };
  }

  function defaultAdd(style) {
    var cssText = style._getCss();

    style._insertCss = function _insertCss() {
      var insertCss = styleManager.insertCss;
      var moduleId = style._module && style._module.id ? style._module.id : "";
      return insertCss(cssText, moduleId);
    };

    if (style._module && style._module.hot && wapp.getTargetObject().hot) {
      wapp.getTargetObject().hot.accept(style._module.id);
    }

    var css = styleManager.css;
    var tempLength = css.size;
    var duplicates = false;
    css.add(style);

    if (tempLength === css.size) {
      duplicates = true;
    }

    if (!duplicates) {
      if (typeof window !== "undefined") {
        var removeCssFunction = style._insertCss();

        style._remove = function _remove() {
          removeCssFunction();
          css["delete"](style);
          styleManager.removeCss["delete"](style._remove);
        };

        styleManager.removeCss.add(style._remove);
        return style._remove;
      } else {
        style._remove = function _remove() {
          css["delete"](style);
          styleManager.removeCss["delete"](style._remove);
        };

        styleManager.removeCss.add(style._remove);
        return style._remove;
      }
    } else {
      style._remove = function () {
        /*fake remove*/
      };

      return style._remove;
    }
  }

  function defaultGetCssText() {
    var globals = wapp.globals || {};
    var _globals$WAPP = globals.WAPP,
        WAPP = _globals$WAPP === void 0 ? "buildHash" : _globals$WAPP,
        DEV = globals.DEV;
    var cssText = (0, _toConsumableArray2["default"])(styleManager.css).map(function (style) {
      return style._getCss();
    }).join("");

    if (!DEV) {
      cssText = cssText.replace(/(\r\n|\n|\r)/gm, " ").replace(/\s+/gm, " ");
    }

    return [{
      id: "css_" + WAPP,
      cssText: cssText
    }];
  }

  function defaultClear() {
    (0, _toConsumableArray2["default"])(styleManager.removeCss).forEach(function (remove) {
      remove();
    });
    styleManager.removeCss = new Set();
    styleManager.css = new Set();
  }

  function defaultUse(styles) {
    var add = styleManager.add;
    return add(styles);
  }

  var defaultCss = new Set();
  var defaultRemoveCss = new Set();
  var styleManager = Object.create(Object.prototype, {
    wapp: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, _utils.defaultDescriptor), {}, {
      writable: false,
      enumerable: false,
      value: wapp
    }),
    insertCss: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, _utils.defaultDescriptor), {}, {
      value: defaultInsertCss
    }),
    css: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, _utils.defaultDescriptor), {}, {
      value: defaultCss
    }),
    removeCss: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, _utils.defaultDescriptor), {}, {
      value: defaultRemoveCss
    }),
    add: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, _utils.defaultDescriptor), {}, {
      value: defaultAdd
    }),
    clear: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, _utils.defaultDescriptor), {}, {
      value: defaultClear
    }),
    use: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, _utils.defaultDescriptor), {}, {
      value: defaultUse
    }),
    getCssText: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, _utils.defaultDescriptor), {}, {
      value: defaultGetCssText
    })
  });
  return styleManager;
}

function createStyleManager() {
  var p = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var wapp = p.wapp,
      _p$styleManager = p.styleManager,
      styleManager = _p$styleManager === void 0 ? createDefaultStyleManager(p) : _p$styleManager;

  function defaultHandle(req, res, next) {
    var targetObject = wapp.getTargetObject ? wapp.getTargetObject() : wapp;

    if (!targetObject.config || targetObject.config && !targetObject.config.styles || targetObject.config && targetObject.config.styles && !targetObject.config.styles.disableClearStyles) {
      stylesMiddleware.styleManager.clear();
    }

    next();
  }

  function defaultAdd(p) {
    return stylesMiddleware.styleManager.add(p);
  }

  function defaultUse(p) {
    return stylesMiddleware.styleManager.use(p);
  }

  function defaultGetCssText() {
    return stylesMiddleware.styleManager.getCssText(p);
  }

  var stylesMiddlewareProperties = Object.create(Object.prototype, {
    handle: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, _utils.defaultDescriptor), {}, {
      value: defaultHandle
    }),
    styleManager: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, _utils.defaultDescriptor), {}, {
      value: styleManager
    }),
    add: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, _utils.defaultDescriptor), {}, {
      value: defaultAdd
    }),
    use: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, _utils.defaultDescriptor), {}, {
      value: defaultUse
    }),
    getCssText: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, _utils.defaultDescriptor), {}, {
      value: defaultGetCssText
    })
  });

  function stylesMiddleware(req, res, next) {
    if (typeof stylesMiddleware.handle === "function") {
      stylesMiddleware.handle(req, res, next);
    }

    return stylesMiddleware;
  }

  (0, _utils.mergeProperties)(stylesMiddleware, stylesMiddlewareProperties);
  Object.defineProperty(stylesMiddleware, "wapp", (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, _utils.defaultDescriptor), {}, {
    writable: false,
    enumerable: false,
    value: wapp
  }));
  Object.defineProperty(wapp, "styles", (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, _utils.defaultDescriptor), {}, {
    writable: false,
    value: stylesMiddleware
  }));
  return stylesMiddleware;
}
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault")["default"];

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = createHistory;

var _objectSpread2 = _interopRequireDefault(require("@babel/runtime/helpers/objectSpread2"));

var _utils = require("../common/utils");

function createPath(_ref) {
  var _ref$pathname = _ref.pathname,
      pathname = _ref$pathname === void 0 ? "/" : _ref$pathname,
      _ref$search = _ref.search,
      search = _ref$search === void 0 ? "" : _ref$search,
      _ref$hash = _ref.hash,
      hash = _ref$hash === void 0 ? "" : _ref$hash;
  return pathname + search + hash;
}

function createHref(to) {
  return typeof to === "string" ? to : createPath(to);
}

function createKey() {
  return Math.random().toString(36).substr(2, 8);
}

function createHistoryManager() {
  var globalHistory = window.history;
  var li = 0;

  function defaultAddListener(handle) {
    if (typeof handle == "function") {
      var handleName = li.toString();
      history.listeners[handleName] = handle;
      li = li + 1;
      return function removeListener() {
        delete history.listeners[handleName];
      };
    }

    return function removeListener() {};
  }

  function defaultRunListeners(args) {
    var listeners = history.listeners;
    Object.keys(listeners).forEach(function (key) {
      var fn = listeners[key];

      if (typeof fn == "function") {
        fn(args);
      }
    });
  }

  function defaultHandlePop() {
    var _window$location = window.location,
        pathname = _window$location.pathname,
        search = _window$location.search,
        hash = _window$location.hash;
    var state = globalHistory.state || {};
    var newLocation = {
      pathname: pathname,
      search: search,
      hash: hash
    };
    state.key = state.key || createKey();
    history.runListeners({
      action: "POP",
      location: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, newLocation), {}, {
        key: state.key
      }),
      state: state
    });
  }

  function defaultInit() {
    if (!history.initialized) {
      window.addEventListener("popstate", history.handlePop);
      history.initialized = true;
    }

    return history;
  }

  function defaultClose() {
    if (history.initialized) {
      window.removeEventListener("popstate", history.handlePop);
      history.initialized = false;
    }

    history.listeners = {};
    return history;
  }

  function defaultParsePath(path) {
    var partialPath = {};

    if (path) {
      var hashIndex = path.indexOf("#");

      if (hashIndex >= 0) {
        partialPath.hash = path.substr(hashIndex);
        path = path.substr(0, hashIndex);
      }

      var searchIndex = path.indexOf("?");

      if (searchIndex >= 0) {
        partialPath.search = path.substr(searchIndex);
        path = path.substr(0, searchIndex);
      }

      if (path) {
        partialPath.pathname = path;
      }
    }

    return partialPath;
  }

  function defaultPush(to, inputState) {
    var _window$location2 = window.location,
        pathname = _window$location2.pathname,
        search = _window$location2.search,
        hash = _window$location2.hash;
    var newLocation = (0, _objectSpread2["default"])({
      pathname: pathname,
      search: search,
      hash: hash
    }, typeof to === "string" ? history.parsePath(to) : to);
    var url = createHref(newLocation);
    var state = inputState || {};
    state.key = state.key || createKey();
    globalHistory.pushState(state, "", url);
    history.runListeners({
      action: "PUSH",
      location: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, newLocation), {}, {
        key: state.key
      }),
      state: state
    });
  }

  function defaultGo(delta) {
    globalHistory.go(delta);
  }

  function defaultListen() {
    var fn = (arguments.length <= 0 ? undefined : arguments[0]) ? arguments.length <= 0 ? undefined : arguments[0] : null;
    history.init();
    return history.addListener(fn);
  }

  function defaultGetState() {
    return history.globalHistory.state || {};
  }

  var history = Object.create(Object.prototype, {
    globalHistory: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, _utils.defaultDescriptor), {}, {
      writable: false,
      enumerable: false,
      value: globalHistory
    }),
    getState: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, _utils.defaultDescriptor), {}, {
      writable: false,
      enumerable: false,
      value: defaultGetState
    }),
    key: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, _utils.defaultDescriptor), {}, {
      value: "initial"
    }),
    listeners: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, _utils.defaultDescriptor), {}, {
      enumerable: false,
      value: {}
    }),
    runListeners: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, _utils.defaultDescriptor), {}, {
      value: defaultRunListeners
    }),
    addListener: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, _utils.defaultDescriptor), {}, {
      value: defaultAddListener
    }),
    listen: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, _utils.defaultDescriptor), {}, {
      value: defaultListen
    }),
    handlePop: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, _utils.defaultDescriptor), {}, {
      enumerable: false,
      value: defaultHandlePop
    }),
    init: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, _utils.defaultDescriptor), {}, {
      value: defaultInit
    }),
    close: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, _utils.defaultDescriptor), {}, {
      value: defaultClose
    }),
    initialized: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, _utils.defaultDescriptor), {}, {
      value: false
    }),
    push: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, _utils.defaultDescriptor), {}, {
      value: defaultPush
    }),
    go: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, _utils.defaultDescriptor), {}, {
      value: defaultGo
    }),
    parsePath: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, _utils.defaultDescriptor), {}, {
      value: defaultParsePath
    })
  });
  return history;
}

function createHistory() {
  var p = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var wapp = p.wapp,
      _p$history = p.history,
      history = _p$history === void 0 ? createHistoryManager() : _p$history;
  var historyProperties = Object.create(Object.prototype, {});
  (0, _utils.mergeProperties)(history, historyProperties);
  Object.defineProperty(history, "wapp", (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, _utils.defaultDescriptor), {}, {
    writable: false,
    enumerable: false,
    value: wapp
  }));
  return history;
}
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault")["default"];

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = createClient;
exports.createMiddleware = createMiddleware;
exports.run = run;

var _objectSpread2 = _interopRequireDefault(require("@babel/runtime/helpers/objectSpread2"));

var _wapplr = _interopRequireDefault(require("wapplr"));

var _reactRender = _interopRequireDefault(require("./reactRender"));

function createClient(p) {
  var wapp = p.wapp || (0, _wapplr["default"])((0, _objectSpread2["default"])({}, p));
  return (0, _reactRender["default"])((0, _objectSpread2["default"])({
    wapp: wapp
  }, p));
}

function createMiddleware() {
  var p = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return function reactMiddleware(req, res, next) {
    var wapp = req.wapp || p.wapp || createClient(p).wapp;
    (0, _reactRender["default"])((0, _objectSpread2["default"])({
      wapp: wapp
    }, p));
    next();
  };
}

var defaultConfig = {
  config: {
    globals: {
      DEV: typeof DEV !== "undefined" ? DEV : undefined,
      WAPP: typeof WAPP !== "undefined" ? WAPP : undefined,
      RUN: typeof RUN !== "undefined" ? RUN : undefined,
      TYPE: typeof TYPE !== "undefined" ? TYPE : undefined,
      ROOT: typeof ROOT !== "undefined" ? ROOT : "/",
      NAME: typeof NAME !== "undefined" ? NAME : undefined
    }
  }
};

function run() {
  var _p$config;

  var p = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultConfig;

  if (p !== null && p !== void 0 && (_p$config = p.config) !== null && _p$config !== void 0 && _p$config.globals && !p.config.globals.RUN) {
    var _p$config2;

    p.config.globals.RUN = ((_p$config2 = p.config) === null || _p$config2 === void 0 ? void 0 : _p$config2.globals.NAME) || "wapplr-react";
  }

  var wapp = createClient(p).wapp;
  var globals = wapp.globals;
  var DEV = globals.DEV;
  var app = wapp.client.app;
  app.use(createMiddleware((0, _objectSpread2["default"])({
    wapp: wapp
  }, p)));
  wapp.client.listen();

  if (typeof DEV !== "undefined" && DEV && module.hot) {
    app.hot = module.hot;
    module.hot.accept();
  }

  return wapp;
}

if (typeof RUN !== "undefined" && RUN === "wapplr-react") {
  run();
}
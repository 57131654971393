"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault")["default"];

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = createMiddlewares;

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _objectSpread2 = _interopRequireDefault(require("@babel/runtime/helpers/objectSpread2"));

var _log = _interopRequireDefault(require("./log"));

var _states = _interopRequireDefault(require("./states"));

var _requests = _interopRequireDefault(require("./requests"));

var _router = _interopRequireDefault(require("./router"));

var _contents = _interopRequireDefault(require("./contents"));

var _styles = _interopRequireDefault(require("./styles"));

var _utils = require("./utils");

function createWappMiddleware() {
  var p = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var wapp = p.wapp;

  function defaultAddHandle(obj) {
    if (typeof obj === "object" && !obj.length) {
      Object.keys(obj).forEach(function (handleName) {
        var handle = obj[handleName];

        if (typeof handle == "function") {
          wappMiddleware.handles[handleName] = handle;
        }
      });
    }
  }

  function defaultWappMiddleware(req, res, next) {
    var globals = wapp.globals;
    var DEV = globals.DEV;
    var config = wapp.getTargetObject().config;
    var _config$containerElem = config.containerElementId,
        containerElementId = _config$containerElem === void 0 ? "app" : _config$containerElem,
        _config$appStateName = config.appStateName,
        appStateName = _config$appStateName === void 0 ? "APP_STATE" : _config$appStateName;
    var container = null;

    if (typeof document !== "undefined") {
      container = containerElementId && document.getElementById(containerElementId) ? document.getElementById(containerElementId) : res.container ? res.container : document.body;

      if (DEV) {
        window.wapp = wapp;
      }
    } // these properties are plus things, usually there are not in request or response
    // timestamp, remoteAddress, container (Html Element on the client side)


    if (!req.timestamp) {
      req.timestamp = Date.now();
    }

    if (!req.remoteAddress) {
      req.remoteAddress = (req.headers && req.headers["x-forwarded-for"] || "").split(",").pop().trim() || req.socket && req.socket.remoteAddress || "::1";
    }

    if (wapp.target === "web" && container) {
      res.container = container;
    }

    var wappRequest = {};
    var wappResponse = {};
    wappRequest.timestamp = req.timestamp;
    wappRequest.path = req.path;
    wappRequest.url = req.url;
    wappRequest.method = req.method;
    wappRequest.httpVersion = req.httpVersion;
    wappRequest.hostname = req.headers && req.headers.host ? req.headers.host : req.authority ? req.authority : req.hostname;
    wappRequest.protocol = wappRequest.httpVersion && wappRequest.httpVersion.startsWith("2") || req.headers && req.headers["x-forwarded-proto"] || req.socket && req.socket.encrypted ? "https" : typeof req.protocol == "string" ? req.protocol : "https";
    wappRequest.secure = wappRequest.protocol === "https" ? true : req.secure;
    wappRequest.remoteAddress = req.remoteAddress;
    wappRequest.userAgent = req.headers && req.headers["user-agent"] ? req.headers["user-agent"] : typeof window !== "undefined" && window.navigator ? window.navigator.userAgent : "";
    wappRequest.query = req.query;
    Object.defineProperty(wappRequest, "wapp", (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, _utils.defaultDescriptor), {}, {
      enumerable: false,
      writable: false,
      value: wapp
    }));
    Object.defineProperty(wappRequest, "req", (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, _utils.defaultDescriptor), {}, {
      enumerable: false,
      writable: false,
      value: req
    }));
    Object.defineProperty(wappRequest, "res", (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, _utils.defaultDescriptor), {}, {
      enumerable: false,
      writable: false,
      value: res
    }));
    wappResponse.statusCode = res.statusCode;
    wappResponse.containerElementId = containerElementId;
    wappResponse.appStateName = appStateName;
    wappResponse.container = container;
    Object.defineProperty(wappResponse, "wapp", (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, _utils.defaultDescriptor), {}, {
      enumerable: false,
      writable: false,
      value: wapp
    }));
    Object.defineProperty(wappResponse, "req", (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, _utils.defaultDescriptor), {}, {
      enumerable: false,
      writable: false,
      value: req
    }));
    Object.defineProperty(wappResponse, "res", (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, _utils.defaultDescriptor), {}, {
      enumerable: false,
      writable: false,
      value: res
    }));
    Object.defineProperty(req, "wapp", (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, _utils.defaultDescriptor), {}, {
      enumerable: false,
      writable: false,
      value: wapp
    }));
    Object.defineProperty(req, "wappRequest", (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, _utils.defaultDescriptor), {}, {
      enumerable: false,
      writable: false,
      value: wappRequest
    }));
    Object.defineProperty(res, "wapp", (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, _utils.defaultDescriptor), {}, {
      enumerable: false,
      writable: false,
      value: wapp
    }));
    Object.defineProperty(res, "wappResponse", (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, _utils.defaultDescriptor), {}, {
      enumerable: false,
      writable: false,
      value: wappResponse
    }));

    wappResponse.status = function status() {
      if (!wappResponse.sended) {
        var http1 = wappRequest.httpVersion === "1.1" || wappRequest.httpVersion && Number(wappRequest.httpVersion.split(".")[0]) === 1;
        var tempStatusCode = wappResponse.statusCode;
        var tempStatusMessage = wappResponse.statusMessage;
        var statusCode = arguments.length <= 0 ? undefined : arguments[0];
        var error = arguments.length <= 1 ? undefined : arguments[1];
        var isError = !!(error && error.message && error.stack);
        wappResponse.statusCode = statusCode;

        if (statusCode) {
          if (statusCode === 200) {
            wappResponse.statusMessage = tempStatusCode === 200 && tempStatusMessage ? tempStatusMessage : "OK";
          }

          if (statusCode === 304) {
            wappResponse.statusMessage = tempStatusCode === 304 && tempStatusMessage ? tempStatusMessage : "Not modified";
          }

          if (statusCode === 404) {
            wappResponse.statusMessage = tempStatusCode === 404 && tempStatusMessage ? tempStatusMessage : "Not found";
          }

          if (statusCode === 500) {
            wappResponse.statusMessage = isError && error.message ? error.name + ": " + error.message : tempStatusCode === 500 && tempStatusMessage ? tempStatusMessage : "Error: Internal Server Error";
          }

          if (http1) {
            res.statusMessage = wappResponse.statusMessage;
          }
        }

        return res.status.apply(res, arguments);
      }
    };

    wappResponse.send = function send() {
      if (!wappResponse.sended) {
        for (var _len = arguments.length, attributes = new Array(_len), _key = 0; _key < _len; _key++) {
          attributes[_key] = arguments[_key];
        }

        var html = attributes[0],
            restAttr = attributes.slice(1);
        wappResponse.sendData = {
          data: html
        };
        wappMiddleware.runSendMiddlewares(req, res, function next() {
          var _wappResponse$sendDat;

          var endHtml = ((_wappResponse$sendDat = wappResponse.sendData) === null || _wappResponse$sendDat === void 0 ? void 0 : _wappResponse$sendDat.data) || "";
          res.send.apply(res, [endHtml].concat((0, _toConsumableArray2["default"])(restAttr)));
          wappResponse.sended = true;
        });
      }
    };

    Object.defineProperty(res, "_originalEndFunction", {
      enumerable: false,
      writable: false,
      configurable: false,
      value: res.end
    });

    res.end = function () {
      res._originalEndFunction.apply(res, arguments);

      wappResponse.sended = true;
    };

    Object.defineProperty(res, "_originalStatusFunction", {
      enumerable: false,
      writable: false,
      configurable: false,
      value: res.status
    });

    res.status = function () {
      if (!wappResponse.sended) {
        res._originalStatusFunction.apply(res, arguments);
      }
    };

    if (wapp.globals.DEV) {
      wapp.wappResponse = wappResponse;
      wapp.wappRequest = wappRequest;
    }

    next();
  }

  function defaultHandle(req, res, out) {
    var handles = Object.keys(wappMiddleware.handles).sort().map(function (key) {
      return wappMiddleware.handles[key];
    });
    var index = 0;

    function next(err) {
      if (handles[index]) {
        var func = handles[index];
        index = index + 1;
        return func(req, res, err ? function () {
          next(err);
        } : next);
      } else if (typeof out === "function") {
        index = 0;
        return out(err);
      }

      return null;
    }

    return next();
  }

  function defaultRunSendMiddlewares(req, res, out) {
    if (wappMiddleware.sendMiddlewares) {
      var next = function next() {
        if (sendMiddlewares[index]) {
          var func = sendMiddlewares[index];
          index = index + 1;
          return func(req, res, next);
        } else if (typeof out == "function") {
          return out();
        }

        return null;
      };

      var sendMiddlewares = Object.keys(wappMiddleware.sendMiddlewares).map(function (key) {
        return wappMiddleware.sendMiddlewares[key];
      });
      var index = 0;
      return next();
    } else if (typeof out == "function") {
      return out();
    }

    return null;
  }

  function defaultAddSendMiddleware(obj) {
    if (typeof obj === "object" && !obj.length) {
      Object.keys(obj).forEach(function (handleName) {
        var handle = obj[handleName];

        if (typeof handle == "function") {
          wappMiddleware.sendMiddlewares[handleName] = handle;
        }
      });
    }
  }

  var wappMiddlewareProperties = Object.create(Object.prototype, {
    handle: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, _utils.defaultDescriptor), {}, {
      value: defaultHandle
    }),
    addHandle: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, _utils.defaultDescriptor), {}, {
      value: defaultAddHandle
    }),
    handles: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, _utils.defaultDescriptor), {}, {
      value: {
        create: defaultWappMiddleware
      }
    }),
    addSendMiddleware: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, _utils.defaultDescriptor), {}, {
      value: defaultAddSendMiddleware
    }),
    runSendMiddlewares: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, _utils.defaultDescriptor), {}, {
      value: defaultRunSendMiddlewares
    }),
    sendMiddlewares: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, _utils.defaultDescriptor), {}, {
      value: {}
    })
  });

  function wappMiddleware(req, res, next) {
    if (typeof wappMiddleware.handle === "function") {
      wappMiddleware.handle(req, res, next);
    }

    return wappMiddleware;
  }

  (0, _utils.mergeProperties)(wappMiddleware, wappMiddlewareProperties);
  Object.defineProperty(wappMiddleware, "wapp", (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, _utils.defaultDescriptor), {}, {
    writable: false,
    enumerable: false,
    value: wapp
  }));
  Object.defineProperty(wapp, "middleware", (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, _utils.defaultDescriptor), {}, {
    writable: false,
    value: wappMiddleware
  }));
  return wappMiddleware;
}

function createMiddlewares(p, step) {
  return {
    wapp: createWappMiddleware(p),
    router: (0, _router["default"])(p),
    states: (0, _states["default"])(p),
    requests: (0, _requests["default"])(p),
    contents: (0, _contents["default"])(p),
    styles: (0, _styles["default"])(p),
    log: (0, _log["default"])(p)
  };
}
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault")["default"];

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = _default;
exports.createMiddleware = createMiddleware;
exports.run = run;

var _objectSpread2 = _interopRequireDefault(require("@babel/runtime/helpers/objectSpread2"));

var _createClient = _interopRequireDefault(require("./createClient"));

var _config = _interopRequireDefault(require("./config"));

// eslint-disable-next-line import/no-anonymous-default-export
function _default() {
  var p = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return p.wapp || (0, _createClient["default"])((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, (0, _config["default"])(p)), p));
}

;

function createMiddleware() {
  var p = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var wapp = p.wapp || (0, _createClient["default"])(p);
  return wapp.client.app;
}

var defaultConfig = {
  config: {
    globals: {
      DEV: typeof DEV !== "undefined" ? DEV : undefined,
      WAPP: typeof WAPP !== "undefined" ? WAPP : undefined,
      RUN: typeof RUN !== "undefined" ? RUN : undefined,
      TYPE: typeof TYPE !== "undefined" ? TYPE : undefined,
      ROOT: typeof ROOT !== "undefined" ? ROOT : "/",
      NAME: typeof NAME !== "undefined" ? NAME : undefined
    }
  }
};

function run(p) {
  var _p$config;

  if (p !== null && p !== void 0 && (_p$config = p.config) !== null && _p$config !== void 0 && _p$config.globals && !p.config.globals.RUN) {
    var _p$config2;

    p.config.globals.RUN = ((_p$config2 = p.config) === null || _p$config2 === void 0 ? void 0 : _p$config2.globals.NAME) || "wapplr";
  }

  var wapp = (0, _createClient["default"])(p);
  var globals = wapp.globals;
  var DEV = globals.DEV;
  var app = wapp.client.app;
  wapp.client.listen();

  if (typeof DEV !== "undefined" && DEV && module.hot) {
    app.hot = module.hot;
    module.hot.accept();
  }

  return wapp;
}

if (typeof RUN !== "undefined" && RUN === "wapplr") {
  run();
}